// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../libs/ID.res.js";

function show(slug) {
  return "/provider_brochures/" + slug;
}

function edit(id) {
  return "/dashboard/provider_brochures/" + ID.toString(id) + "/edit";
}

var Dashboard = {
  index: "/dashboard/provider_brochures",
  $$new: "/dashboard/provider_brochures/new",
  edit: edit
};

var index = "/provider_brochures";

export {
  index ,
  show ,
  Dashboard ,
}
/* ID Not a pure module */
