// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Link from "../../../../styleguide/components/Link/Link.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as IconSelect from "../../../../styleguide/icons/IconSelect.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ProviderSetting from "../../../../models/ProviderSetting.res.js";
import * as Routes_Provider from "../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as FetchProvidersDashboardRequest from "../../../../api/providers/requests/FetchProvidersDashboardRequest.res.js";
import * as ProvidersDashboardOptionsScss from "./ProvidersDashboardOptions.scss";

var css = ProvidersDashboardOptionsScss;

function ProvidersDashboardOptions(props) {
  var sortBy = props.sortBy;
  var page = "page=" + String(props.pageNum);
  var query = Belt_Option.getWithDefault(Belt_Option.map(props.query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "Locations" ? "&sort_by=locations_count&sort_direction=" + FetchProvidersDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
      variant === "CreatedAt" ? "&sort_by=providers.created_at&sort_direction=" + FetchProvidersDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
          variant === "Master" ? "&sort_by=provider_settings.master&sort_direction=" + FetchProvidersDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
              variant === "IsApproved" ? "&sort_by=is_approved&sort_direction=" + FetchProvidersDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
                  variant === "Managers" ? "&sort_by=provider_users_counter_cache&sort_direction=" + FetchProvidersDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : (
                      variant === "Name" ? "&sort_by=name&sort_direction=" + FetchProvidersDashboardRequest.Req.SortDirection.toString(sortBy.VAL) : "&sort_by=active&sort_direction=" + FetchProvidersDashboardRequest.Req.SortDirection.toString(sortBy.VAL)
                    )
                )
            )
        )
    );
  var permissions = props.permissions.map(function (permission) {
          return "&settings[]=" + ProviderSetting.Field.toFieldName(permission);
        }).join("");
  var url = "/api/v1/dashboard/providers.csv?" + (page + (query + (sortBy$1 + permissions)));
  var tmp;
  tmp = props.userRole === "Admin" ? JsxRuntime.jsxs(Link.make, {
          href: url,
          className: css.popoverBodyLink,
          children: [
            "Download CSV",
            JsxRuntime.jsx(IconSelect.make, {
                  size: "MD",
                  color: "LightestGray",
                  className: css.popoverBodyLinkIcon
                })
          ]
        }) : null;
  return JsxRuntime.jsxs(Popover.make, {
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Primary",
                            className: css.ctaButton,
                            children: "Options",
                            iconSuffixComponent: Caml_option.some(JsxRuntime.jsx(IconSelect.make, {
                                      size: "LG",
                                      color: "White"
                                    }))
                          })
                    }),
                JsxRuntime.jsxs(Popover.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: css.popoverBody,
                      children: [
                        JsxRuntime.jsxs("a", {
                              children: [
                                "New Provider",
                                JsxRuntime.jsx(IconSelect.make, {
                                      size: "MD",
                                      color: "LightestGray",
                                      className: css.popoverBodyLinkIcon
                                    })
                              ],
                              className: css.popoverBodyLink,
                              href: Routes_Provider.Dashboard.$$new
                            }),
                        tmp
                      ]
                    })
              ]
            });
}

var make = ProvidersDashboardOptions;

export {
  css ,
  make ,
}
/* css Not a pure module */
